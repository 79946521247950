import { Dialog, Transition } from '@headlessui/react';
import { useState, useEffect, Fragment } from 'react';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { XIcon } from '@heroicons/react/solid';
import {
  ArrowNarrowLeftIcon,
  ChatIcon,
  EyeIcon,
  HeartIcon,
  LinkIcon,
  ReplyIcon,
  ShareIcon,
} from '@heroicons/react/outline';
import Button from '../Button';
import VerifiedIcon from '../Icons/verified';
import TwitterBirdIcon from '../Icons/twitter';
import UserSidebar from '../PostElements/UserSidebar';
import PostTopBar from '../NavContainer/PostTopBar';
import ImagePreviewModal from '../NewModals/image-preview';
import { client } from '../../utils/http';
var twitter = require('twitter-text');

export default function ThreadPage({
  thread,
  threadCreatorSlug,
  threadCreatorID,
  creatorMeta,
  threadSlug,
  type = 'thread',
  publishedAtUnix = null,
}) {
  const [activeThread, setActiveThread] = useState([]);
  const [activeThreadSlug, setActiveThreadSlug] = useState('');
  const [activeThreadHasImages, setActiveThreadHasImages] = useState([]);
  const [activeThreadTweetLink, setActiveThreadTweetLink] = useState('');
  const [imageIDArray, setImageIDArray] = useState([]);
  const [imageDataArray, setImageDataArray] = useState([]);
  const [linkPreviews, setLinkPreviews] = useState([]);
  const [quoteTweetArray, setQuoteTweetArray] = useState([]);

  const [cachedTweets, setCachedTweets] = useState([]);
  const [cachedPreviews, setCachedPreviews] = useState([]);

  const [imageURLArray, setImageURLArray] = useState([]);

  const [activeImageModalSRC, setActiveImageModalSRC] = useState('');
  const [imageModalOpen, setImageModalOpen] = useState(false);

  const [loading, setLoading] = useState(true);

  const getDate = (date) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const newDate = new Date(date).toLocaleDateString(undefined, options);
    return newDate;
  };

  useEffect(() => {
    if (thread) {
      setActiveThread(thread.tweetArray);
      setActiveThreadSlug(threadSlug);
      updateData(thread.tweetArray);
    }
  }, [thread]);

  const updateData = (passedTweetArray) => {
    if (thread.tweetIDs) {
      setActiveThreadTweetLink(thread.tweetIDs[0]);
    }

    if (thread.quotes) {
      Object.keys(thread.quotes.quoteTweetArray).map((quoteTweet, index) => {
        if (
          thread.quotes.cachedTweets[thread.quotes.quoteTweetArray[quoteTweet]]
        ) {
          getQuoteTweet(
            thread.quotes.quoteTweetArray[quoteTweet],
            quoteTweet,
            thread.quotes.cachedTweets[
              thread.quotes.quoteTweetArray[quoteTweet]
            ],
            passedTweetArray,
          );
        }
      });
    }

    if (thread.linkPreviews) {
      Object.keys(thread.linkPreviews).map((linkPreview, index) => {
        if (thread.linkPreviews[linkPreview]) {
          console.log(linkPreview, passedTweetArray.length);
          if (linkPreview > passedTweetArray.length - 1) {
            //dont do anything
          } else {
            getLinkData(
              thread.linkPreviews[linkPreview],
              linkPreview,
              passedTweetArray,
            );
          }
        }
      });
    }

    if (thread.imageArray) {
      getImages(thread.imageArray, threadSlug);
    } else {
      if (thread.newImageArray) {
        setImageIDArray(thread.newImageArray);

        //loop through quoteteTweetArray and getQuoteTweet
        /* thread.quoteTweetArray.map((quoteTweet, index) => {

                }) */

        //loop through new image array and get images from db
        Object.keys(thread.newImageArray).map((imageArray, index) => {
          if (thread.newImageArray[imageArray]) {
            Object.keys(thread.newImageArray[imageArray]).map(
              (image, index) => {
                //loop through image array and get images from db

                getImageFromDB(
                  threadCreatorID,
                  thread.newImageArray[imageArray][image],
                  threadSlug,
                );
              },
            );
          }
        });
      } else {
        if (thread.media) {
          setImageURLArray(thread.media);
        } else {
          setActiveThreadHasImages([]);
        }
      }
    }
    setLoading(false);
  };

  const getCachedTweet = (tweetID) => {
    //get tweet from cachedTweet array

    var tweet = cachedTweets[tweetID];
    return tweet;
  };

  const getCachedPreview = (link) => {
    //get tweet from cachedTweet array
    var link = cachedPreviews[link];
    return link;
  };

  async function getQuoteTweet(tweetID, index, data, passedTweetArray) {
    setCachedTweets((prevState) => ({
      ...prevState,
      [tweetID]: data,
    }));

    setQuoteTweetArray((prevState) => {
      const newArray = [...prevState];
      newArray[index] = tweetID;
      return newArray;
    });

    var tweetLink =
      'https://twitter.com/' + data.user.handle + '/status/' + tweetID;

    //find tweetLink in tweetAtIndex and remove it
    var tweetAtIndex = passedTweetArray[index];

    if (tweetAtIndex) {
      var tweetAtIndex = tweetAtIndex.replace(tweetLink, '').trim();

      setActiveThread((prevState) => {
        const newArray = [...prevState];
        newArray[index] = tweetAtIndex;
        return newArray;
      });
    }

    /* //find tweetLink in activeThread array at index and remove it
        var tweetIndex = activeThread.findIndex(tweet => tweet.tweetID === tweetID)
        console.log("tweet index", tweetIndex)
        var newActiveThread = [...activeThread]
    
        newActiveThread.splice(tweetIndex, 1)
        setActiveThread(newActiveThread)
    */
  }

  async function getLinkData(link, index, passedTweetArray) {
    try {
      const { data } = await client.post('/api/social-blog/link-preview', {
        url: link,
      });
      if (!data.success) {
        throw new Error('failed to get link preview');
      }
      var temp = {
        title: data.meta.title,
        description: data.meta.description,
        image: data.meta.image,
        url: link,
        display_url: link
          .replace('https://', '')
          .replace('http://', '')
          .replace('www.', '')
          .replace(/\/$/, '')
          .split('/')[0],
      };

      setCachedPreviews((prevState) => ({
        ...prevState,
        [link]: temp,
      }));

      //add link data.meta to array at index
      setLinkPreviews((prevState) => {
        const newArray = [...prevState];
        newArray[index] = link;
        return newArray;
      });

      var linkAtIndex = passedTweetArray[index];
      var linkAtIndex = linkAtIndex
        .replace(`\n${link}`, '')
        .replace(`${link}\n`, '')
        .trim();

      setActiveThread((prevState) => {
        const newArray = [...prevState];
        newArray[index] = linkAtIndex;
        return newArray;
      });
    } catch (err) {
      console.error(err);
    }
  }

  const getImages = (imageArray, tslug) => {
    try {
      var imgArr = [];

      const isArr = Array.isArray(imageArray);

      if (isArr === false) {
        for (const [key, value] of Object.entries(imageArray)) {
          imgArr[key] = value;
        }
      } else {
        imgArr = imageArray;
      }

      var tempArr = [];

      imgArr.forEach((imageName, i) => {
        tempArr.push(i);

        var storageRef2 = firebase.storage().ref();
        var imgRef = storageRef2
          .child(threadCreatorID)
          .child(tslug)
          .child(imageName);

        imgRef
          .getDownloadURL()
          .then((url) => {
            console.log('url', url);
            // Or inserted into an <img> element
            var imgID = `tweet-image-${i}`;
            var img = document.getElementById(imgID);
            if (img !== null) {
              img.setAttribute('src', url);
            }
          })
          .catch((error) => {
            console.log(error);
            // Handle any errors
          });
      });

      setActiveThreadHasImages(tempArr);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageFromDB = (userID, imageName, slug) => {
    try {
      if (userID && imageName && slug) {
        var storageRef = firebase.storage().ref();
        var imgRef = storageRef.child(userID).child(slug).child(`${imageName}`);

        imgRef.getDownloadURL().then(onResolve, onReject);

        function onResolve(url) {
          // This can be downloaded directly:
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            var blob = xhr.response;
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async function () {
              setImageDataArray((prevState) => ({
                ...prevState,
                [imageName]: reader.result,
              }));
            };
          };
          xhr.open('GET', url);
          xhr.send();
        }

        function onReject(error) {
          if (error.code === 'storage/object-not-found') {
          } else {
            var imgRefOriginal = storageRef
              .child(userID)
              .child(slug)
              .child(`${imageName}`);
            imgRefOriginal.getDownloadURL().then((url) => {
              // This can be downloaded directly:
              var xhr = new XMLHttpRequest();
              xhr.responseType = 'blob';
              xhr.onload = (event) => {
                var blob = xhr.response;
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async function () {
                  setImageDataArray((prevState) => ({
                    ...prevState,
                    [imageName]: reader.result,
                  }));

                  /*  var img = document.getElementById(imageElementID);
                                     if (img !== null) {
                                         img.src = reader.result;
                                     } */
                };
              };
              xhr.open('GET', url);
              xhr.send();
            });
          }
        }
      }
    } catch (error) {
      alert('Image not found - do not alter the image code');
    }
  };

  const openImageModal = (src) => {
    setImageModalOpen(true);
    setActiveImageModalSRC(src);
  };

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  const pages = [
    { name: 'Projects', href: '#', current: false },
    { name: 'Project Nero', href: '#', current: true },
  ];

  return (
    <div className="flex min-h-screen w-full flex-col items-center justify-start bg-white antialiased ">
      <ImagePreviewModal
        image={activeImageModalSRC}
        isOpen={imageModalOpen}
        setIsOpen={setImageModalOpen}
      />

      <PostTopBar
        photoUrl={creatorMeta.image}
        galleryName={creatorMeta.slug}
        displayName={creatorMeta.displayName}
        creatorMeta={creatorMeta}
      />

      <div className="relative inline-block w-full max-w-[1024px] transform py-12 px-6 text-left align-bottom transition-all xl:px-0">
        <div
          className={
            'relative flex flex-col items-start space-y-16 p-0 md:flex-row md:space-y-0 md:space-x-24'
          }
        >
          <div
            className={
              ' flex  w-full max-w-xl flex-col space-y-4 font-sans antialiased'
            }
          >
            <div className="flex flex-col space-y-4">
              {thread.title ? (
                <h1 className="font-primary text-4xl font-bold text-gray-900">
                  {thread.title}
                </h1>
              ) : (
                <div className="flex flex-col items-start justify-between space-y-2 sm:flex-row sm:items-center sm:space-y-0">
                  <div className="flex flex-row flex-nowrap items-center justify-start space-x-3">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={creatorMeta.image}
                    />

                    <div className="flex flex-col flex-nowrap items-start justify-center -space-y-1 overflow-hidden font-sans text-base text-gray-500">
                      <p
                        className={`flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-primary font-semibold text-gray-900`}
                      >
                        {creatorMeta.displayName}
                      </p>
                      <p className="flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-thread text-sm">
                        @{creatorMeta.twitterURL}
                      </p>
                    </div>
                  </div>

                  {thread.tweetIDs ? (
                    <a
                      href={`https://twitter.com/${creatorMeta.twitterURL}/status/${thread.tweetIDs[0]}`}
                      target={'_blank'}
                    >
                      <Button
                        size={'mini'}
                        prefix={
                          <TwitterBirdIcon
                            className={'relative z-10 -mr-1 h-4 w-4'}
                          />
                        }
                      >
                        View on Twitter
                      </Button>
                    </a>
                  ) : null}
                  {/* <Button size={"small"} variant={"secondary"}>View on Twitter</Button> */}
                </div>
              )}

              <div className="flex w-full flex-row justify-between">
                <div className="flex flex-row space-x-2">
                  <Button size={'mini'} variant={'secondary'}>
                    Thread
                  </Button>
                  <Button
                    size={'mini'}
                    color={'bg-gray-100 group-hover:bg-gray-200'}
                    customText={'text-gray-500'}
                  >
                    {publishedAtUnix
                      ? getDate(publishedAtUnix)
                      : getDate(thread.publishedAt.unix)}
                  </Button>
                </div>
                {/* 
                                <Button size={"mini"} variant={"secondary"} prefix={<TwitterBirdIcon className={"w-4 h-4 -mr-1"} />}>View on Twitter</Button> */}
              </div>

              {/*      <div className=' flex flex-row space-x-4 text-gray-500 text-sm font-medium'>
                                <div className='flex flex-row space-x-1 items-center justify-center'>
                                    <EyeIcon className='w-4 h-4' />
                                    <p>10,986</p>
                                </div>

                                <div className='flex flex-row space-x-1 items-center justify-center'>
                                    <HeartIcon className='w-4 h-4' />
                                    <p>262</p>
                                </div>

                                <div className='flex flex-row space-x-1 items-center justify-center'>
                                    <ReplyIcon className='w-4 h-4' />
                                    <p>42</p>
                                </div>

                                <div className='flex flex-row space-x-1 items-center justify-center'>
                                    <ChatIcon className='w-4 h-4' />
                                    <p>13</p>
                                </div>


                            </div> */}
            </div>

            {activeThread.map((tweet, i) => (
              <div className={' w-full py-2'}>
                <div
                  className={
                    'flex w-full flex-col items-start space-y-2 overflow-ellipsis text-left font-thread'
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: autoLinkTweet(tweet) }}
                    class={'tweet-text-lg'}
                  ></div>

                  {activeThreadHasImages.includes(i) ? (
                    <img
                      className={'mx-auto  w-full rounded-2xl'}
                      id={`tweet-image-${i}`}
                    />
                  ) : null}

                  {imageIDArray[i] ? (
                    imageIDArray[i].length > 0 ? (
                      <div
                        className={`grid w-full ${
                          imageIDArray[i].length < 3
                            ? imageIDArray[i].length === 1
                              ? 'grid-cols-1 grid-rows-2'
                              : 'h-[200px] grid-cols-2 grid-rows-1 gap-x-1'
                            : 'h-[250px] grid-cols-2 grid-rows-2 gap-1'
                        } w-full overflow-hidden rounded-2xl`}
                      >
                        {imageIDArray[i].map((imageID, index) => (
                          <button
                            onClick={() =>
                              openImageModal(
                                imageDataArray[imageIDArray[i][index]],
                              )
                            }
                            className={` overflow-hidden ${
                              imageIDArray[i].length === 1 ? 'h-auto' : 'h-full'
                            } group relative w-full object-cover ${
                              index === 0
                                ? imageIDArray[i].length === 4
                                  ? 'col-span-1 row-span-1'
                                  : 'col-span-1 row-span-2'
                                : index === 1
                                ? 'col-span-1 row-span-1'
                                : index === 2
                                ? 'col-span-1 row-span-1'
                                : ''
                            } `}
                          >
                            <img
                              id={`image-${i}-${index}`}
                              className={` overflow-hidden ${
                                imageIDArray[i].length === 1
                                  ? 'h-auto'
                                  : 'h-full'
                              } group relative w-full object-cover `}
                              src={imageDataArray[imageIDArray[i][index]]}
                            />
                          </button>
                        ))}
                      </div>
                    ) : null
                  ) : null}

                  {imageURLArray[i] ? (
                    imageURLArray[i].length > 0 ? (
                      <div
                        className={`grid w-full ${
                          imageURLArray[i].length < 3
                            ? imageURLArray[i].length === 1
                              ? 'grid-cols-1 grid-rows-2'
                              : 'h-[200px] grid-cols-2 grid-rows-1 gap-x-1'
                            : 'h-[250px] grid-cols-2 grid-rows-2 gap-1'
                        } w-full overflow-hidden rounded-2xl`}
                      >
                        {imageURLArray[i].map((imageID, index) => (
                          <button
                            onClick={() =>
                              openImageModal(imageURLArray[i][index].url)
                            }
                            className={` overflow-hidden ${
                              imageURLArray[i].length === 1
                                ? 'h-auto'
                                : 'h-full'
                            } group relative w-full object-cover ${
                              index === 0
                                ? imageURLArray[i].length === 4
                                  ? 'col-span-1 row-span-1'
                                  : 'col-span-1 row-span-2'
                                : index === 1
                                ? 'col-span-1 row-span-1'
                                : index === 2
                                ? 'col-span-1 row-span-1'
                                : ''
                            } `}
                          >
                            <img
                              id={`image-${i}-${index}`}
                              className={` overflow-hidden ${
                                imageURLArray[i].length === 1
                                  ? 'h-auto'
                                  : 'h-full'
                              } group relative w-full object-cover `}
                              src={imageURLArray[i][index].url}
                            />
                          </button>
                        ))}
                      </div>
                    ) : null
                  ) : null}

                  {linkPreviews[i] ? (
                    <a
                      href={getCachedPreview(linkPreviews[i]).url}
                      target={'_blank'}
                      className="flex w-full cursor-pointer flex-col overflow-hidden rounded-2xl border-2 border-gray-100 bg-white antialiased duration-200 hover:bg-gray-50"
                    >
                      <div
                        className={
                          'flex-center flex h-auto w-full justify-center bg-white'
                        }
                      >
                        <img
                          className={'h-auto w-full'}
                          src={getCachedPreview(linkPreviews[i]).image}
                        />
                      </div>
                      <div
                        className={
                          'flex flex-col items-start justify-start p-4 font-sans text-sm text-gray-500'
                        }
                      >
                        <div className="flex flex-row items-center justify-start space-x-1">
                          <LinkIcon className="h-3 w-3 " />
                          <p
                            className={`w-32 truncate overflow-ellipsis font-sans text-sm`}
                          >
                            {getCachedPreview(linkPreviews[i]).display_url}
                          </p>
                        </div>
                        <p
                          className={
                            'mt-2 mb-1 w-full flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-sans text-base font-medium text-gray-900'
                          }
                        >
                          {getCachedPreview(linkPreviews[i]).title}
                        </p>
                        <p>{getCachedPreview(linkPreviews[i]).description}</p>
                      </div>
                    </a>
                  ) : null}

                  {quoteTweetArray[i] ? (
                    <div
                      layout="position"
                      transition={{ duration: 0.2 }}
                      className={'w-full'}
                    >
                      <a
                        href={getCachedTweet(quoteTweetArray[i]).linkToTweet}
                        target={'_blank'}
                        className="flex w-full cursor-pointer flex-col rounded-2xl border-2 border-gray-100 bg-white p-6 antialiased duration-200 hover:bg-gray-50"
                      >
                        <div className="flex flex-row flex-nowrap items-center justify-start space-x-3">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={getCachedTweet(quoteTweetArray[i]).user.image}
                          />

                          <div className="flex flex-col flex-nowrap items-start justify-center -space-y-1 overflow-hidden font-sans text-base text-gray-500">
                            <p
                              className={`flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-sans text-base font-semibold tracking-tight text-gray-900`}
                            >
                              {getCachedTweet(quoteTweetArray[i]).user.name}
                            </p>
                            <p className="flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-thread text-sm">
                              @{getCachedTweet(quoteTweetArray[i]).user.handle}
                            </p>
                          </div>
                        </div>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: autoLinkTweet(
                              getCachedTweet(quoteTweetArray[i]).text,
                            ),
                          }}
                          class={'tweet-text-thread-main mt-2'}
                        ></div>

                        {getCachedTweet(quoteTweetArray[i]).media[0].type ===
                        'photo' ? (
                          <img
                            className={'mt-2 w-full rounded-lg'}
                            src={
                              getCachedTweet(quoteTweetArray[i]).media[0].url
                            }
                          />
                        ) : null}

                        <p className="mt-4 flex-nowrap overflow-hidden overflow-ellipsis whitespace-nowrap font-thread text-sm text-gray-500">
                          {getDate(getCachedTweet(quoteTweetArray[i]).date)}
                        </p>
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}

            {/* <div className=' border-gray-100 bg-gray-50 border-2 rounded-2xl flex flex-col '>
                            <div className='flex flex-row items-center justify-between p-6'>

                                <div className="flex flex-row space-x-3 items-center justify-start flex-nowrap">
                                    <img className="w-10 h-10 rounded-full" src={"/sams.jpeg"} />

                                    <div className="flex flex-col items-start -space-y-2 justify-center text-gray-500 font-sans text-base overflow-hidden flex-nowrap">
                                        <p className={`text-lg font-semibold font-sans text-gray-900 whitespace-nowrap flex-nowrap overflow-ellipsis overflow-hidden`}>
                                            Sam Shore
                                        </p>
                                        <p className="whitespace-nowrap flex-nowrap font-thread overflow-ellipsis text-sm overflow-hidden">@samjshore</p>

                                    </div>

                                </div>
                                <a href={`https://twitter.com/${creatorMeta.twitterURL}/status/${thread.tweetIDs[0]}`} target={"_blank"}>
                                    <Button size={"small"} variant={"secondary"}>View on Twitter</Button>
                                </a>
                            </div>

                        </div> */}
          </div>

          <UserSidebar creatorMeta={creatorMeta} />
        </div>
      </div>
    </div>
  );
}
